import ChargeCardOfferRep from "reps/ChargeCardOfferRep";

const isChargeCardOfferWithCashback = (chargeCardOffer: ChargeCardOfferRep.Complete): boolean => {
  const cardCreditTerm = chargeCardOffer.creditTerms;
  switch (cardCreditTerm) {
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxExtend:
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionExtend:
      return false;
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxFlex:
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionFlex:
    case ChargeCardOfferRep.ChargeCardCreditTerm.Sandbox:
    case ChargeCardOfferRep.ChargeCardCreditTerm.Production:
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxCash:
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionCash:
      return true;
  }
};

export default isChargeCardOfferWithCashback;
