import IconWithTooltip from "components/common/IconWithTooltip";
import { CashBackInfoIconWithTooltip } from "resources/capital-accounts/constants";
import { useCapitalAccountSummaryContext } from "resources/capital-accounts/context/capital-account-summary-context";
import useChargeCardOffer from "resources/charge-card-offer/queries/useChargeCardOffer";
import isChargeCardOfferWithCashback from "resources/charge-card-offer/utils/isChargeCardOfferWithCashback";
import CashDisplay from "ui/data-display/money/CashDisplay";
import SummaryArray from "ui/data-display/SummaryArray";
import Text from "ui/typography/Text";

import styles from "./LineOfCreditSummaryItems.module.scss";

const ChargeCardSummaryItems = () => {
  const capitalAccountSummary = useCapitalAccountSummaryContext();
  const chargeCardOffer = useChargeCardOffer(capitalAccountSummary.guid);
  const hasCashback = chargeCardOffer ? isChargeCardOfferWithCashback(chargeCardOffer) : false;

  return (
    <SummaryArray className={styles.summaryArray}>
      <SummaryArray.Item className={styles.summaryArrayItem}>
        <div className={styles.tooltipContainer}>
          <Text size={14}>Limit</Text>
          <IconWithTooltip
            color="dark"
            content="This limit will grow over time with healthy credit usage"
          />
        </div>
        <CashDisplay cents={capitalAccountSummary.details.limit} />
      </SummaryArray.Item>
      {hasCashback && (
        <SummaryArray.Item className={styles.summaryArrayItem}>
          <div className={styles.tooltipContainer}>
            <Text size={14}>Cash back</Text>
            <CashBackInfoIconWithTooltip />
          </div>
          <Text size={28} weight="medium">
            {chargeCardOffer?.maxCashback}%
          </Text>
        </SummaryArray.Item>
      )}
      <SummaryArray.Item className={styles.summaryArrayItem}>
        <Text size={14}>Repayment period</Text>
        <Text size={28} weight="medium">
          Up to {capitalAccountSummary.details.targetRepaymentDays} days
        </Text>
      </SummaryArray.Item>
    </SummaryArray>
  );
};

export default ChargeCardSummaryItems;
