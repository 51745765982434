/* eslint sort-keys: ["error", "asc", { "natural": true }] */

/**
 * Boolean, on/off flags
 */
export const PERMANENT_FEATURE_FLAGS = {
  CLOSE_BANK_ACCOUNT: "close-bank-account",
  CREDIT_ALLOW_SKIP_STORES: "credit-allow-skip-stores",
  REQUIRE_STORE_CONNECTION_IN_ONBOARDING: "require-store-connection-in-onboarding",
};

export const TEMPORARY_FEATURE_FLAGS = {
  ALLOW_CA_CREDIT_APPLICATIONS: "capital-allow-ca-credit-applications",
  BILL_PAY_APPROVERS_UI: "bill-pay-approvers-ui",
  BILL_PAY_UI: "bill-pay-ui",
  CAPITAL_OVERVIEW_PAGE: "capital-overview-page",
  CAPITAL_PRODUCTS_HIGHBEAM_CARD: "capital-products-highbeam-card",
  CAPITAL_PROMOTIONAL_RATES: "capital-promotional-rates",
  CAPITAL_UNACCEPTED_AGREEMENT_FLOW: "capital-unaccepted-agreement-flow",
  CASH_FLOW_VIEW: "cash-flow-view",
  CHANGE_ORGANIZATION_OWNER: "change-organization-owner",
  CHARGE_CARD_MULTIPLE_CAPITAL_ACCOUNTS_CARD_CREATION_MODAL:
    "charge-card-multipe-capital-accounts-card-creation-modal",
  CONNECT_STORES_DURING_ONBOARDING: "connect-stores-during-onboarding",
  CREDIT_APPLICATION_REQUIREMENTS_MODAL: "credit-application-requirements-modal",
  DRAW_DOWN_WITH_APPROVAL_REQUIRED: "drawdown-with-approval-required",
  EDIT_USER_ROLE: "edit-user-role",
  EMPLOYEE_USER_ROLE_INVITATION: "employee-user-role-invitation",
  FORCE_MIGRATE_TO_THREAD: "force-migrate-to-thread",
  HIDE_CONNECTED_STORES: "hide-connected-stores",
  INCLUDE_ADJUSTMENT_TRANSACTIONS_AS_INTEREST: "include-adjustment-transactions-as-interest",
  INCOMING_PAYMENTS: "incoming-payments",
  MIGRATE_TO_THREAD: "migrate-to-thread",
  ONBOARDING_PERSONAL_INFORMATION_FORM: "onboarding-personal-information-form",
  PAYMENT_FLOW_ALLOW_BANK_ACCOUNT_UPDATE: "payment-flow-allow-bank-account-update",
  PAYMENT_SUMMARY_V2_IN_EARLY_PAY: "payment-summary-v-2-in-early-pay",
  REFRESH_JWT_BEFORE_INITIAL_AUTHORIZATION: "refresh-jwt-before-initial-authorization",
  SAME_DAY_ACH_VIP: "same-day-ach-domestic-ach",
  SPECIAL_INTEREST_CUSTOMER: "prevent-interest-tier-switching",
  SPEND_BY_VENDOR_ALLOW_RECATEGORIZATION: "spend-by-vendor-allow-recategorization",
  SPEND_BY_VENDOR_SHOW_TRANSACTIONS_FROM_EXTERNAL_SOURCES:
    "spend-by-vendor-show-transactions-from-external-sources",
  SPEND_BY_VENDOR_UI: "insights-ui",
  SPEND_BY_VENDOR_V2: "spend-by-vendor-v2",
};

export const FEATURE_FLAGS = {
  ...PERMANENT_FEATURE_FLAGS,
  ...TEMPORARY_FEATURE_FLAGS,
};

/**
 * String values
 */
export const FEATURE_VALUES = {
  example: "replace-me-this-is-here-for-type-safety",
};
