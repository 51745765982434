import CapitalAccountRep from "reps/CapitalAccountRep";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";

export type CapitalAccountSummaryWithChargeCard =
  | CapitalAccountSummaryRep.ChargeCardAndCashAccessComplete
  | CapitalAccountSummaryRep.ChargeCardOnlyComplete;

const isCapitalAccountSummaryWithChargeCard = (
  capitalAccountSummary: CapitalAccountSummaryRep.Complete
): capitalAccountSummary is CapitalAccountSummaryWithChargeCard => {
  return (
    capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardAndCashAccess ||
    capitalAccountSummary.type === CapitalAccountRep.Type.ChargeCardOnly
  );
};

export default isCapitalAccountSummaryWithChargeCard;
