import IconWithTooltip from "components/common/IconWithTooltip";
import { FC } from "react";
import CapitalAccountSummaryRep from "reps/CapitalAccountSummaryRep";
import isCapitalAccountSummaryWithChargeCard, {
  CapitalAccountSummaryWithChargeCard,
} from "resources/capital-accounts/utils/isCapitalAccountSummaryWithChargeCard";
import {
  chargeCardOfferMaximumRepaymentPeriodDays,
  StatementCycleExplanation,
} from "resources/charge-card-offer/constants/repayment-periods";
import useChargeCardOffer from "resources/charge-card-offer/queries/useChargeCardOffer";
import OfferDetails from "ui/data-display/OfferDetails";

type ChargeCardRepaymentOfferDetailsItemProps = {
  capitalAccountSummaryWithChargeCard: CapitalAccountSummaryWithChargeCard;
};

const ChargeCardRepaymentOfferDetailsItem: FC<ChargeCardRepaymentOfferDetailsItemProps> = ({
  capitalAccountSummaryWithChargeCard,
}) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountSummaryWithChargeCard.guid, {
    required: true,
  });

  return (
    <OfferDetails.Item
      label={
        <>
          Repayment period
          <IconWithTooltip
            color="dark"
            content={<StatementCycleExplanation chargeCardOffer={chargeCardOffer} />}
          />
        </>
      }
      value={`Up to ${chargeCardOfferMaximumRepaymentPeriodDays[chargeCardOffer.creditTerms]} days`}
    />
  );
};

type Props = {
  capitalAccountSummary: CapitalAccountSummaryRep.Complete;
};

const CapitalAccountCardRepaymentPeriodOfferDetailsItem: FC<Props> = ({
  capitalAccountSummary,
}) => {
  if (isCapitalAccountSummaryWithChargeCard(capitalAccountSummary)) {
    return (
      <ChargeCardRepaymentOfferDetailsItem
        capitalAccountSummaryWithChargeCard={capitalAccountSummary}
      />
    );
  }

  return (
    <OfferDetails.Item
      label="Repayment period"
      value={`${capitalAccountSummary.details.targetRepaymentDays} days`}
    />
  );
};

export default CapitalAccountCardRepaymentPeriodOfferDetailsItem;
