import { FC } from "react";
import ChargeCardOfferRep from "reps/ChargeCardOfferRep";

export const chargeCardOfferMaximumRepaymentPeriodDays = {
  [ChargeCardOfferRep.ChargeCardCreditTerm.Production]: 45,
  [ChargeCardOfferRep.ChargeCardCreditTerm.Sandbox]: 45,
  [ChargeCardOfferRep.ChargeCardCreditTerm.ProductionExtend]: 60,
  [ChargeCardOfferRep.ChargeCardCreditTerm.ProductionFlex]: 30,
  [ChargeCardOfferRep.ChargeCardCreditTerm.ProductionCash]: 1,
  [ChargeCardOfferRep.ChargeCardCreditTerm.SandboxExtend]: 60,
  [ChargeCardOfferRep.ChargeCardCreditTerm.SandboxFlex]: 30,
  [ChargeCardOfferRep.ChargeCardCreditTerm.SandboxCash]: 1,
} satisfies { [key in ChargeCardOfferRep.ChargeCardCreditTerm]: number };

type StatementCycleExplanationProps = {
  chargeCardOffer: ChargeCardOfferRep.Complete;
};

export const StatementCycleExplanation: FC<StatementCycleExplanationProps> = ({
  chargeCardOffer,
}) => {
  switch (chargeCardOffer.creditTerms) {
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionFlex:
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxFlex:
      return (
        <>
          Statements are monthly, and the outstanding balance is due{" "}
          <strong>the first day of the following month</strong>.
        </>
      );
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionExtend:
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxExtend:
      return (
        <>
          Statements are monthly, and the outstanding balance is due{" "}
          <strong>on the last day of the following month</strong>.
        </>
      );
    case ChargeCardOfferRep.ChargeCardCreditTerm.ProductionCash:
    case ChargeCardOfferRep.ChargeCardCreditTerm.SandboxCash:
      return (
        <>
          Statements are daily, and the outstanding balance is due{" "}
          <strong>on the following day</strong>.
        </>
      );
    case ChargeCardOfferRep.ChargeCardCreditTerm.Production:
    case ChargeCardOfferRep.ChargeCardCreditTerm.Sandbox:
      return (
        <>
          Statements are monthly, and the outstanding balance is due{" "}
          <strong>on the 15th of the following month</strong>.
        </>
      );
  }
};
