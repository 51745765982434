import { ReactNode } from "react";
import colors from "styles/colors";
import CollapsibleAccordion from "ui/inputs/CollapsibleAccordion";
import Heading3 from "ui/typography/Heading3";
import Text from "ui/typography/Text";
import stringToReactKey from "utils/react-helpers/stringToReactKey";

import styles from "./CapitalFaqs.module.scss";

type FaqType = {
  question: string;
  answer: ReactNode;
};

const FAQ_CONTENT: FaqType[] = [
  {
    question: "What is a revolving line of credit?",
    answer: (
      <>
        A revolving line of credit is a preset borrowing limit that can be utilized or repaid at any
        time. This means you can withdraw money as needed until the limit is reached. As the loan is
        repaid, the money becomes available again for withdrawal.
      </>
    ),
  },
  {
    question: "How much credit can I get from Highbeam?",
    answer: (
      <>
        The amount varies, but typically Highbeam will start at 15 days of sales, and grow to a
        brand’s average monthly revenue.
      </>
    ),
  },
  {
    question: "How long will it take to activate my line of credit and see cash in my account?",
    answer: (
      <>
        It varies between application and business size, but it can be from 3 business days up to 2
        weeks.
      </>
    ),
  },
  {
    question: "What is the minimum required revenue to qualify for a line of credit?",
    answer: (
      <>
        Our revolving line of credit is designed to address working capital needs for brands with
        $500,000 or more annual ecommerce revenue. Some exceptions are made however. For example for
        those in our BuyBox beta program.
      </>
    ),
  },
  {
    question: "What can cause my limit to change over time?",
    answer: (
      <div>
        <>
          Because Highbeam’s line of credit is an unsecured, revenue-based line of credit, the limit
          will increase and decrease over time depending on a number of factors including:
        </>
        <ul className={styles.list}>
          <li>
            <>New debt or financing</>
          </li>
          <li>
            <>Year-over-year change in sales</>
          </li>
          <li>
            <>Runway and liquidity levels</>
          </li>
          <li>
            <>Credit utilization and on-time repayments</>
          </li>
        </ul>
        <>
          In the case your business takes out any new financing, in order to keep it from affecting
          your line of credit limit, always consult Highbeam during the decision-making process
          because there are cases where we can try to meet your additional financing needs
          ourselves.
        </>
      </div>
    ),
  },
  {
    question: "What is your repayment period?",
    answer: (
      <>
        Our repayment period is 4-6 months. Depending on your selected repayment structure:
        percentage of payouts vs daily installments. If your current repayment strategy does not
        repay the amount drawn within 4-6 months of drawdown, then your auto-pay rate will be
        adjusted automatically.
      </>
    ),
  },
  {
    question: "Does Highbeam’s line of credit for allow mixing of other credit providers?",
    answer: (
      <>
        Highbeam works well with traditional lenders such as traditional banks, SBA, as well as
        secured lenders such as asset-based lenders. However, Highbeam’s line of credit doesn’t mix
        well with MCAs and other alternative lenders since we are an unsecured, revenue-based line
        of credit. In the case your business takes out any new financing, in order to keep it from
        affecting your line of credit limit, always consult Highbeam during the decision-making
        process because there are cases where we can try to meet your additional financing needs
        ourselves.
      </>
    ),
  },
  {
    question: "Will applying for capital affect my credit score?",
    answer: <>No, your credit score will not be affected when you apply for Highbeam Capital.</>,
  },
];

const CapitalFaqs = () => {
  return (
    <div className={styles.container}>
      <Heading3>FAQs</Heading3>

      {FAQ_CONTENT.map((faq, index) => (
        <CollapsibleAccordion
          key={stringToReactKey(faq.question, index)}
          heading={
            <Text size={14} color={colors.grey[600]}>
              {faq.question}
            </Text>
          }
        >
          <Text size={14} color={colors.grey[800]} weight="regular">
            {faq.answer}
          </Text>
        </CollapsibleAccordion>
      ))}
    </div>
  );
};

export default CapitalFaqs;
