import classNames from "classnames";
import { ReactNode, useEffect, useState, useCallback } from "react";
import PlaidBankAccountRep from "reps/PlaidBankAccountRep";
import useDeferredPlaidLinkToken from "resources/plaid-connections/queries/useDeferredPlaidLinkToken";
import useRefreshPlaidConnectionsQuery from "resources/plaid-connections/queries/useRefreshPlaidConnectionsQuery";
import { useIsSuperusering } from "state/auth/isSuperusering";
import Card from "ui/data-display/Card";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import { getPlaidBankAccountDisplayName } from "utils/account";
import useHighbeamPlaidLink from "utils/customHooks/useHighbeamPlaidLink";
import useIsAllowedToConnectBankAccounts from "utils/permissions/useIsAllowedToConnectBankAccounts";
import useIsAllowedToConnectCards from "utils/permissions/useIsAllowedToConnectCards";

import Heading3 from "../../../../ui/typography/Heading3";
import { ConnectedIntegrationIndicator } from "../ConnectedIntegrationIndicator";
import DeletePlaidConnectionModal from "../DeletePlaidConnectionModal";

import styles from "./PlaidConnectionCard.module.scss";

type ReconnectButtonProps = {
  connectionGuid: string;
  accountType: PlaidBankAccountRep.AccountType;
};

const ReconnectButton: React.FC<ReconnectButtonProps> = ({ connectionGuid, accountType }) => {
  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();
  const isAllowedToConnectCards = useIsAllowedToConnectCards();

  const isDisabled =
    (accountType === "DEPOSITORY" && !isAllowedToConnectBankAccounts) ||
    (accountType === "CREDIT" && !isAllowedToConnectCards);

  const { linkToken, createLinkToken, isCreatingLinkToken } = useDeferredPlaidLinkToken({
    connectionGuid: connectionGuid,
    accountType,
    allowAccountSelectionOnUpdate: true,
  });

  const [isPlaidOpened, setIsPlaidOpened] = useState(false);
  const refreshPlaidConnectionsQuery = useRefreshPlaidConnectionsQuery();
  const { openPlaid, isPlaidReady } = useHighbeamPlaidLink({
    linkToken,
    onComplete: () => {
      refreshPlaidConnectionsQuery();
    },
    isNewConnection: false,
  });

  const onClick = useCallback(async () => {
    await createLinkToken();
    // Reset isPlaidOpened to false so that the Plaid link again once the linkToken is created.
    // We do this here to account for the case where the user opens Plaid a first time, exits it, and then clicks the button again.
    setIsPlaidOpened(false);
  }, [createLinkToken]);

  useEffect(() => {
    if (linkToken && isPlaidReady && !isPlaidOpened) {
      openPlaid();
      setIsPlaidOpened(true);
    }
  }, [linkToken, isPlaidReady, openPlaid, isPlaidOpened]);

  return (
    <Button
      variant="primary"
      onClick={onClick}
      className={styles.addAccountButton}
      isLoading={isCreatingLinkToken}
      disabled={isDisabled}
      tooltip={
        isDisabled &&
        (accountType === "DEPOSITORY" ? (
          <Text size={14}>You don’t have permission to connect bank accounts.</Text>
        ) : (
          <Text size={14}>You don’t have permission to connect cards.</Text>
        ))
      }
    >
      Reconnect
    </Button>
  );
};

type Props = {
  name: string;
  connectionGuid: string;
  plaidItemId: string;
  isActive: boolean;
  logo?: ReactNode;
  accounts: PlaidBankAccountRep.Complete[];
  accountType: PlaidBankAccountRep.AccountType;
  className?: string;
};

const PlaidConnectionCard: React.FC<Props> = ({
  name,
  connectionGuid,
  plaidItemId,
  isActive,
  logo,
  accounts,
  accountType,
  className,
}) => {
  const refreshPlaidConnectionsQuery = useRefreshPlaidConnectionsQuery();

  const [showDeleteConnectionModal, setShowDeleteConnectionModal] = useState(false);
  const isSuperusering = useIsSuperusering();
  const openItemInPlaidDashboard = useCallback(() => {
    const url = `https://dashboard.plaid.com/activity/debugger/${plaidItemId}`;
    window.open(url, "_blank")?.focus();
  }, [plaidItemId]);

  return (
    <>
      {isSuperusering && showDeleteConnectionModal && (
        <DeletePlaidConnectionModal
          onClose={() => {
            setShowDeleteConnectionModal(false);
          }}
          onDelete={() => {
            setShowDeleteConnectionModal(false);
            refreshPlaidConnectionsQuery();
          }}
          connectionGuid={connectionGuid}
          name={name}
        />
      )}
      <Card className={classNames(className, styles.plaidConnectionCard)} shadow="xs">
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            {logo}
            <Text size={14} weight="bold">
              {name}
            </Text>
          </div>

          <ConnectedIntegrationIndicator isActive={isActive} />
        </div>
        <div className={styles.bodyWrapper}>
          {accounts?.map((account) => (
            <div key={account.plaidAccountId} className={styles.integrationTextRow}>
              <Text size={14} weight="medium">
                {getPlaidBankAccountDisplayName(account)}
              </Text>
            </div>
          ))}
        </div>
        {isActive ? null : (
          <ReconnectButton connectionGuid={connectionGuid} accountType={accountType} />
        )}
        {isSuperusering ? (
          <div className={styles.superuserArea}>
            <Heading3>Superuser</Heading3>
            <Button variant="secondary" onClick={openItemInPlaidDashboard}>
              Open in Plaid
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                setShowDeleteConnectionModal(true);
              }}
            >
              Delete connection
            </Button>
          </div>
        ) : null}
      </Card>
    </>
  );
};

export default PlaidConnectionCard;
