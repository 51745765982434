import { PlusCircle } from "@phosphor-icons/react";
import emptyExternalBankAccounts from "assets/empty-external-bank-accounts.svg";
import { getIcon, getInstitutionNameFromId } from "components/Accounts/AccountLabel";
import DashboardPage from "components/layouts/DashboardPage";
import { FC } from "react";
import PlaidLinkTokenRep from "reps/PlaidLinkTokenRep";
import useHasPlaidConnections from "resources/plaid-connections/queries/useHasPlaidConnections";
import usePlaidDepositoryConnections from "resources/plaid-connections/queries/usePlaidDepositoryConnections";
import usePlaidLinkToken from "resources/plaid-connections/queries/usePlaidLinkToken";
import useRefreshPlaidConnectionsQuery from "resources/plaid-connections/queries/useRefreshPlaidConnectionsQuery";
import EmptyCardsList from "ui/data-display/EmptyCardsList";
import Button from "ui/inputs/Button";
import Text from "ui/typography/Text";
import useHighbeamPlaidLink from "utils/customHooks/useHighbeamPlaidLink";
import useIsAllowedToConnectBankAccounts from "utils/permissions/useIsAllowedToConnectBankAccounts";

import PlaidConnectionCard from "../SettingsPage/PlaidConnectionCard";

import styles from "./SettingsFinancialPartnersPage.module.scss";

type Props = {
  setAddConnectionModalVariant: (variant: PlaidLinkTokenRep.AccountType) => void;
  onComplete?: () => void;
};

export const EmptyExternalBankAccountState: FC<Props> = ({
  setAddConnectionModalVariant,
  onComplete,
}) => {
  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();
  const hasPlaidConnections = useHasPlaidConnections();
  const refreshPlaidConnectionsQuery = useRefreshPlaidConnectionsQuery();

  const { openPlaid } = useHighbeamPlaidLink({
    linkToken: usePlaidLinkToken({
      accountType: "DEPOSITORY",
      allowAccountSelectionOnUpdate: false,
    }),
    onComplete: () => {
      refreshPlaidConnectionsQuery();
      onComplete?.();
    },
  });

  return (
    <EmptyCardsList>
      <img src={emptyExternalBankAccounts} alt="No connected bank accounts" />
      <Button
        disabled={!isAllowedToConnectBankAccounts}
        variant="secondary"
        onClick={() => {
          if (!hasPlaidConnections) {
            openPlaid();
          } else {
            setAddConnectionModalVariant("DEPOSITORY");
          }
        }}
        tooltip={
          !isAllowedToConnectBankAccounts && (
            <Text size={14}>You don’t have permission to connect bank accounts.</Text>
          )
        }
      >
        <PlusCircle />
        Connect an account
      </Button>
    </EmptyCardsList>
  );
};

const ExternalBankAccountsSection: FC<Props> = ({ setAddConnectionModalVariant }) => {
  const depositoryConnections = usePlaidDepositoryConnections();

  const isAllowedToConnectBankAccounts = useIsAllowedToConnectBankAccounts();
  const hasPlaidConnections = useHasPlaidConnections();
  const refreshPlaidConnectionsQuery = useRefreshPlaidConnectionsQuery();

  const { openPlaid } = useHighbeamPlaidLink({
    linkToken: usePlaidLinkToken({
      accountType: "DEPOSITORY",
      allowAccountSelectionOnUpdate: false,
    }),
    onComplete: () => {
      refreshPlaidConnectionsQuery();
    },
  });

  return (
    <DashboardPage.Section>
      <DashboardPage.Section.Header
        heading="Connected bank accounts"
        subheading="Connect your other bank accounts so you can transfer funds between them and Highbeam."
        actions={
          depositoryConnections.length > 0 && (
            <Button
              disabled={!isAllowedToConnectBankAccounts}
              variant="secondary"
              onClick={() => {
                if (!hasPlaidConnections) {
                  openPlaid();
                } else {
                  setAddConnectionModalVariant("DEPOSITORY");
                }
              }}
              tooltip={
                !isAllowedToConnectBankAccounts && (
                  <Text size={14}>You don’t have permission to connect bank accounts.</Text>
                )
              }
            >
              <PlusCircle />
              Add new connection
            </Button>
          )
        }
      />

      <DashboardPage.Section.Body>
        {depositoryConnections.length === 0 && (
          <EmptyExternalBankAccountState
            setAddConnectionModalVariant={setAddConnectionModalVariant}
          />
        )}

        <div className={styles.integrationsWrapper}>
          {depositoryConnections.map((connection) => (
            <PlaidConnectionCard
              key={connection.guid}
              name={
                connection.institutionName ??
                getInstitutionNameFromId(connection.accounts[0].institutionId, "bank")
              }
              connectionGuid={connection.guid}
              plaidItemId={connection.plaidItemId}
              isActive={connection.isActive}
              logo={getIcon(connection.accounts[0].institutionId)}
              accounts={connection.accounts}
              accountType="DEPOSITORY"
            />
          ))}
        </div>
      </DashboardPage.Section.Body>
    </DashboardPage.Section>
  );
};

export default ExternalBankAccountsSection;
