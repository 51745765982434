import {
  ArrowFatLinesDown,
  ArrowsClockwise,
  Cards,
  ChartLine,
  CurrencyCircleDollar,
} from "@phosphor-icons/react";
import { FC } from "react";
import CapitalAccountRep from "reps/CapitalAccountRep";
import useChargeCardOffer from "resources/charge-card-offer/queries/useChargeCardOffer";
import isChargeCardOfferWithCashback from "resources/charge-card-offer/utils/isChargeCardOfferWithCashback";
import colors from "styles/colors";
import Text from "ui/typography/Text";

import styles from "../views/ReviewLineOfferViews.module.scss";

type ChargeCardOfferDetailsProps = {
  capitalAccountGuid: string;
};

const ChargeCardOfferDetails: FC<ChargeCardOfferDetailsProps> = ({ capitalAccountGuid }) => {
  const chargeCardOffer = useChargeCardOffer(capitalAccountGuid);
  const hasCashback = chargeCardOffer ? isChargeCardOfferWithCashback(chargeCardOffer) : false;

  return (
    <div className={styles.howItWorksItemContainer}>
      <div className={styles.howItWorksItem}>
        <div className={styles.howItWorksItemIcon}>
          <ChartLine size={20} color={colors.purple[500]} />
        </div>
        <Text size={14} color={colors.grey[900]}>
          Your limit is based on your current and historical debt, assets, and sales
        </Text>
      </div>
      <div className={styles.howItWorksItem}>
        <div className={styles.howItWorksItemIcon}>
          <Cards size={20} color={colors.purple[500]} />
        </div>
        <Text size={14} color={colors.grey[900]}>
          After activating this line, create Highbeam Cards and connect them to this line
        </Text>
      </div>
      <div className={styles.howItWorksItem}>
        <div className={styles.howItWorksItemIcon}>
          <CurrencyCircleDollar size={20} color={colors.purple[500]} />
        </div>
        {hasCashback ? (
          <Text size={14} color={colors.grey[900]}>
            Earn up to <strong>{chargeCardOffer?.maxCashback}% cash back</strong> across all
            approved vendors
          </Text>
        ) : (
          <Text size={14} color={colors.grey[900]}>
            Enjoy 30 days of interest free float
          </Text>
        )}
      </div>
    </div>
  );
};

const LineOfCreditOfferDetails = () => (
  <div className={styles.howItWorksItemContainer}>
    <div className={styles.howItWorksItem}>
      <div className={styles.howItWorksItemIcon}>
        <ChartLine size={20} color={colors.purple[500]} />
      </div>
      <Text size={14} color={colors.grey[900]}>
        Your limit is based on your current and historical debt, assets, and sales
      </Text>
    </div>
    <div className={styles.howItWorksItem}>
      <div className={styles.howItWorksItemIcon}>
        <ArrowFatLinesDown size={20} color={colors.purple[500]} />
      </div>
      <Text size={14} color={colors.grey[900]}>
        Draw down funds instantly and repay your line at any time
      </Text>
    </div>
    <div className={styles.howItWorksItem}>
      <div className={styles.howItWorksItemIcon}>
        <ArrowsClockwise size={20} color={colors.purple[500]} />
      </div>
      <Text size={14} color={colors.grey[900]}>
        After every drawdown you’ll start repaying it with auto-pay
      </Text>
    </div>
  </div>
);

type Props = {
  capitalAccount: CapitalAccountRep.Complete;
};

const LineOfferDetailsSection: FC<Props> = ({ capitalAccount }) => {
  return (
    <>
      {capitalAccount.type === CapitalAccountRep.Type.ChargeCardOnly ? (
        <ChargeCardOfferDetails capitalAccountGuid={capitalAccount.guid} />
      ) : (
        <LineOfCreditOfferDetails />
      )}
    </>
  );
};

export default LineOfferDetailsSection;
